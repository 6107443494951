import React, { useState } from 'react';
import { ExternalLink } from 'lucide-react';
import '../styles/style.css';
import '../styles/mediaqueries.css';
import aboutMePic from "../assets/aboutMe.jpg";
import experienceIcon from "../assets/experience.png";
import educationIcon from "../assets/education.png";
import arrowIcon from "../assets/arrow.png";

function About() {
    const [isHovered, setIsHovered] = useState(false);

    const openCertificateLink = () => {
        window.open('https://moringa.my.salesforce-sites.com/certificateStatus?id=a0PQ200000ClXw4', '_blank', 'noopener,noreferrer');
    };

    return (
        <section id="about">
            <p className="section__text__p1">Get To Know More</p>
            <h1 className="title">About Me</h1>
            <div className="section-container">
                <div className="section__pic-container">
                    <img
                        src={aboutMePic}
                        alt="Profile picture"
                        className="about-pic"
                    />
                </div>
                <div className="about-details-container">
                    <div className="about-containers">
                        <div className="details-container">
                            <img
                                src={experienceIcon}
                                alt="Experience icon"
                                className="icon"
                            />
                            <h3>Experience</h3>
                            <p>As a backend developer, I'm passionate about crafting IoT and robotics solutions,
                                leveraging my expertise to drive innovation in these cutting-edge fields.</p>
                        </div>
                        <div className="details-container">
                            <img
                                src={educationIcon}
                                alt="Education icon"
                                className="icon"
                            />
                            <h3>Education</h3>
                            <p className="flex items-center gap-2">
                                Data Science, Moringa School
                                <ExternalLink
                                    className="cursor-pointer"
                                    size={16}
                                    color={isHovered ? '#3b82f6' : '#000'}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    onClick={openCertificateLink}
                                />
                            </p>
                            <p>B.Sc. Computer Science at Machakos University</p>
                        </div>
                    </div>
                    <div className="text-container">
                        <p>
                            I'm Samwel Waithaka, a proud graduate with a BSc in Computer Science from Machakos
                            University. My academic journey ignited my passion for backend systems, robotics,
                            IoT, and AI, equipping me with a versatile skill set in full-stack development.
                            I've designed user-friendly interfaces, optimized database performance, and developed
                            efficient systems that seamlessly blend form and function.
                            Beyond coding, I'm captivated by the intersection of technology and art, particularly
                            through 3D design and game development, which I pursue during my free time. This
                            creative lens enriches my technical expertise, enabling me to approach challenges
                            with fresh perspectives.
                            I'm driven by a desire to innovate and make a positive impact. I look forward to
                            collaborating with like-minded individuals and organizations on transformative projects.
                            Let's connect and build something remarkable together!
                        </p>
                    </div>
                </div>
            </div>
            <img
                src={arrowIcon}
                alt="Arrow icon"
                className="icon arrow"
                onClick={() => window.location.href = '#experience'}
            />
        </section>
    );
}

export default About;