import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/style.css';
import '../styles/mediaqueries.css';
import SearchBar from "./SearchBar";

function Header() {
    const [searchInput, setSearchInput] = useState('');
    const location = useLocation();

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const toggleMenu = () => {
        const menu = document.querySelector(".menu-links");
        const icon = document.querySelector(".hamburger-icon");
        menu.classList.toggle("open");
        icon.classList.toggle("open");
    };

    return (
        <div>
            <nav id="desktop-nav">
                <div className="logo" onClick={() => window.location.href = '/'} style={{ cursor: 'pointer' }}>
                    Samwel Waithaka
                </div>

                <div className="search-container">
                    {/*{location.pathname === '/projects' && <SearchBar />}*/}
                </div>

                <div>
                    <ul className="nav-links">
                        <li><a href="#about">About</a></li>
                        <li><a href="#experience">Experience</a></li>
                        <li><a href="#projects">Projects</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
            </nav>
            <nav id="hamburger-nav">
                <div className="logo" onClick={() => window.location.href = '/'} style={{ cursor: 'pointer' }}>Samwel Waithaka</div>
                <div className="hamburger-menu">
                    <div className="hamburger-icon" onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="menu-links">
                        <li><a href="#about" onClick={toggleMenu}>About</a></li>
                        <li><a href="#experience" onClick={toggleMenu}>Experience</a></li>
                        <li><a href="#projects" onClick={toggleMenu}>Projects</a></li>
                        <li><a href="#contact" onClick={toggleMenu}>Contact</a></li>
                    </div>
                </div>
            </nav>
            {/* Components for Profile, About, Experience, Projects, Contact, Footer */}
        </div>
    );
}

export default Header;
